import React from 'react';

// Components
import { Box, Heading } from '@chakra-ui/layout';

// Reusable styles
const styles = {
  fullscreen: {
    width: '95vw',
    maxWidth: '5xl',
  },
};

const Card = ({ header = '', children, type = '', sx = {} } = {}) => {
  const style = styles[type] || {};

  return (
    <Box
      sx={{
        background: 'white',
        borderRadius: 'md',
        shadow: '0 2px 8px 0 rgba(0, 0, 0, .2)',
        padding: 4,
        ...style,
        ...sx,
      }}
    >
      <Heading sx={{ marginBottom: 2 }}>{header}</Heading>
      {children}
    </Box>
  );
};

export default Card;
