import React from 'react';

// Components
import { Box, Heading, ListItem, Text, UnorderedList } from '@chakra-ui/layout';

export const Milestone = ({
  icon,
  title = '',
  description = '',
  children,
} = {}) => {
  // Nodes are displayed under the milestone, don't render a <ul> if there are no children
  const nodes =
    children != null ? <UnorderedList>{children}</UnorderedList> : null;

  return (
    <Box sx={{ marginTop: 4 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            WebkitMaskImage: '-webkit-radial-gradient(white, black)', // Required for the border radius to work on Safari
            borderRadius: 8,
            height: 16,
            width: 16,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {icon}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            marginLeft: 4,
          }}
        >
          <Heading sx={{ fontSize: 'md' }} as="h3">
            {title}
          </Heading>
          <Text>{description}</Text>
        </Box>
      </Box>
      {nodes}
    </Box>
  );
};

export const Node = ({ title = '', description = '' } = {}) => {
  return (
    <ListItem
      sx={{
        marginLeft: 8,
        marginTop: 4,
        paddingLeft: 8,
        '::marker': {
          fontSize: 'xl',
        },
      }}
    >
      <Box>
        <Heading sx={{ fontSize: 'sm' }} as="h4">
          {title}
        </Heading>
        <Text sx={{ fontSize: 'sm' }}>{description}</Text>
      </Box>
    </ListItem>
  );
};

export const Timeline = ({ sx = {}, children } = {}) => {
  return <Box sx={sx}>{children}</Box>;
};

export default Timeline;
