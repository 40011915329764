import React from 'react';

// Components
import { Box, Text } from '@chakra-ui/layout';
import Card from '../components/atoms/card';
import Section from '../components/molecules/section';
import { Timeline, Milestone, Node } from '../components/organisms/timeline';
import { StaticImage } from 'gatsby-plugin-image';

const Home = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Card header="Who am I?" type="fullscreen" sx={{ marginBottom: 4 }}>
        <Text>
          Hey there! My name is Maxton, pleased to meet you. Born and raised in
          the Bay Area, I've been surrounded by "Big Tech" for my entire life.
          This sparked my initial interest in software engineering which has now
          turned into my full-time career. My passion for all things tech
          started young with building and repairing computers, this eventually
          turned into writing scripts and building small web applications.
        </Text>
        <Text sx={{ marginTop: 2 }}>
          A couple years ago while attending college, I went heads down learning
          JavaScript, React, and Serverless. I embarked on this journey not only
          because I loved tech, but because I was in a tough spot financially. I
          had heard of many students attending coding bootcamps and finding
          success, I wagered I could find the same success on my own. I'm very
          thankful to say that through hard work and a great mentor, I was able
          to find this success.
        </Text>
        <Text sx={{ marginTop: 2 }}>
          Software engineering has changed my life and I am eternally grateful.
        </Text>
      </Card>
      <Card header="Skills" type="fullscreen" sx={{ marginBottom: 4 }}>
        <Section header="Frontend">
          <Text>
            React, HTML, CSS, TypeScript, JavaScript, Web Accessibility, SEO,
            Gatsby, Next, Netlify, LaunchDarkly, Apollo GraphQL, Enzyme, Jest,
            Cypress
          </Text>
        </Section>
        <Section header="Backend" sx={{ marginTop: 4 }}>
          <Text>
            AWS (Lambda, DynamoDB, S3, etc.), Serverless, Node.js, REST,
            GraphQL, Micro-services, Apollo Federation, NoSQL, JWT, O.Auth,
            Docker, Datadog
          </Text>
        </Section>
        <Section header="Soft skills / Workflow" sx={{ marginTop: 4 }}>
          <Text>
            Communication, team player, wears many hats, leadership,
            architecture, problem solving, adaptability
          </Text>
          <Text>Scrum, Khanban, Jira, Confluence, TDD, CI/CD</Text>
        </Section>
      </Card>
      <Card header="Experience" type="fullscreen">
        <Timeline>
          <Milestone
            icon={
              <StaticImage
                src="../images/timeline/goodleap.png"
                alt="GoodLeap"
                placeholder="blurred"
              />
            }
            title="GoodLeap"
            description="May 2019 - Current"
          >
            <Node
              title="Sr. Software Engineer - Tech Lead"
              description={`
                  Engineering tech lead on the Core API team. Drive projects from start to finish with a team of 7 engineers. 
                  Work closely with a product team to plan upcoming work, determining technical gaps that need to be filled, and document solutions. 
                  Work with the team to size work and provide timeline estimates for projects. 
                  Collaborate across teams to ensure all dependencies are met and work is unblocked. 
                  Mentor team members through pair programming and technical discussions to promote growth across the team.
                `}
            />
            <Node
              title="Software Engineer"
              description={`
                  Full-stack, creating internal / external web applications and APIs. Deliver high performing products on strict deadlines,
                  given full liberty to take initiative on design / implementation. Less focus on maintenance and more focus on building
                  new scalable infrastructure and retrofitting as needed.
                `}
            />
            <Node
              title="Jr. Software Engineer"
              description={`
                  Full-stack, supporting internal / external web applications and APIs.
                  Focused primarily on delivering smaller features and maintaining existing infrastructure.
                `}
            />
          </Milestone>
        </Timeline>
      </Card>
    </Box>
  );
};

export default Home;
