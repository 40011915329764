import React from 'react';

// Components
import { Box, Heading } from '@chakra-ui/layout';

const Section = ({ header = '', children = '', sx = {} }) => (
  <Box as="section" sx={sx}>
    <Heading sx={{ fontSize: 'xl' }} as="h3">
      {header}
    </Heading>
    {children}
  </Box>
);

export default Section;
